
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import Transfer from "@/views/bike/transfer/transferModal/Main.vue";

export default defineComponent({
  components: {
    Grid,
    Transfer
  },
  setup() {
    const transferBike = ref(false);
    const confirmState = ref(false);
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const gridOptions: GridOptions = {
      stripe: false,
      title: "車輛庫存",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        // custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "車輛庫存",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Number",
          title: "車輛編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "歸屬產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Time",
          title: "調出時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "State",
          title: "調度狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          type: 'html',
        },
        {
          field: "operate",
          title: "操作",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "150",
          slots: { default: "column-operate" }
        }
      ],
      promises: {
        query: () => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
              data: [
                  {
                    Number: '123A',
                    ProductName: '單人電輔車',
                    Time: "2022/10/01 12:33",
                    State: "<span class='text-red-500'>待確認</span>",
                  },
                  {
                    Number: '123A',
                    ProductName: '單人電輔車',
                    Time: "2022/10/01 12:33",
                    State: "<span class='text-gray-500'>已完成</span>",
                  },
                ],
                totalCount: 1,
              });
            }, 100);
          });
        },
        queryAll: model ? () => model.dispatch('order/query') : undefined,
        save: model
          ? (params) => model.dispatch('order/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };

    return {
      grid,
      gridOptions,
      transferBike,
      confirmState
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
  },
});
